import React, { ReactElement } from "react";

import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/SEO";

// Component imports
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image";
import Section from "../components/Section";
import { motion } from "framer-motion";
import moment from "moment/moment";
import Rule from "../components/Rule";
import Text from "../components/Text";

const rules = [
    {
        title: "// TIER 1",
        content: "",
        // "All Tier 1 offenses will be prefaced with a verbal warning for the first offense followed by a warning or mute and progressively more severe punishments.",
        blocks: [
            {
                title: "SPAMMING",
                textVersion: `The repetition of messages or letters being sent in the chat. The same message being sent three or more times in a row will be considered spam. This includes private messages to other players, commands that spam players’ chats, excessive characters, etc...`,
                content: (
                    <Text variant="p">
                        The repetition of messages or letters being sent in the
                        chat. The same message being sent three or more times in
                        a row will be considered spam. This includes private
                        messages to other players, commands that spam players’
                        chats, excessive characters, etc...
                        <br />
                        <br />
                        15 or more characters in a row is considered character
                        spamming.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: Verbal Warning</li>
                            <li>3rd Offense: 1 Hour Mute</li>
                            <li>4th Offense: 3 Hour Mute</li>
                            <li>5th Offense 6 Hour Mute</li>
                        </ul>
                    </Text>
                ),
            },

            {
                title: "CAUSING / ENCOURAGING SPAM",
                textVersion: `The act of causing or encouraging spam in any way is not allowed. This includes but is not limited to phrases such as “first to say 9 wins 1m”, “message this person for a reward”, and more. Telling people or a group to say a certain thing also falls under this rule.`,
                content: (
                    <Text variant="p">
                        The act of causing or encouraging spam in any way is not
                        allowed. This includes but is not limited to phrases
                        such as “first to say 9 wins 1m”, “message this person
                        for a reward”, and more. Telling people or a group to
                        say a certain thing also falls under this rule.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: Verbal Warning</li>
                            <li>3rd Offense: 1 Hour Mute</li>
                            <li>4th Offense: 3 Hour Mute</li>
                            <li>5th Offense 6 Hour Mute</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "HARASSMENT",
                textVersion:
                    "The act of continually pestering, hounding, or asking favors from players / members of staff after explicitly being asked to stop.",
                content: (
                    <Text variant="p">
                        The act of continually pestering, hounding, or asking
                        favors from players / members of staff after explicitly
                        being asked to stop.
                        <br />
                        <br />
                        This includes cursing at other members of the SpaceDelta
                        community.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: Verbal Warning</li>
                            <li>3rd Offense: 1.5 Hour Mute</li>
                            <li>4th Offense: 3 Hour Mute</li>
                            <li>5th Offense 1 Hour Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "OTHER LANGUAGES",
                textVersion:
                    "We absolutely encourage players from all sorts of diverse cultures - In fact, several high ranking staff aren’t native English speakers. However, as English is the only requirement for our moderation team, we ask that you refrain from using other languages in public chat. This rule does not apply in private messages or chats.",
                content: (
                    <Text variant="p">
                        We absolutely encourage players from all sorts of
                        diverse cultures - In fact, several high ranking staff
                        aren’t native English speakers. However, as English is
                        the only requirement for our moderation team, we ask
                        that you refrain from using other languages in public
                        chat. This rule does not apply in private messages or
                        chats.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: Verbal Warning</li>
                            <li>3rd Offense: 30 Minute Mute</li>
                            <li>4th Offense: 1h Mute</li>
                            <li>5th Offense: 2h Mute</li>
                            <li>6th Offense: 3h Mute</li>
                        </ul>
                    </Text>
                ),
            },
        ],
    },
    {
        title: "// TIER 2",
        content:
            // "All Tier 2 offenses will be met with a warning or mute depending on severity followed by bans and progressively severe punishments.",
            "",
        blocks: [
            // {
            //     title: "DISCRIMINATION",
            //     textVersion:
            //         "The use of sexist, racist, homophobic, or otherwise harmful slurs to insult someone in the community. These words are censored for the most part, but are here for reference in case users attempt to bypass the censor. ",
            //     content: (
            //         <Text variant="p">
            //             The use of sexist, racist, homophobic, or otherwise
            //             harmful slurs to insult someone in the community. These
            //             words are censored for the most part, but are here for
            //             reference in case users attempt to bypass the censor.
            //             <br />
            //             <br />
            //             <strong className="italic">Special Cases:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>
            //                     “Gay” - The word “gay” to describe a negative
            //                     feeling or situation is not allowed, as this is
            //                     innately homophobic.
            //                 </li>
            //                 <li>
            //                     “Retarded” - The word “retarded” is not allowed
            //                     at all, due to it’s highly offensive
            //                     connotations.
            //                 </li>
            //             </ul>
            //             <br />
            //             <br />
            //             <strong className="italic">Punishments:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>1st Offense: 12h Mute</li>
            //                 <li>2nd Offense: 1d Mute</li>
            //                 <li>3rd Offense: 3d Mute</li>
            //                 <li>4th Offense: 7d Mute</li>
            //                 <li>5th Offense: 14d Mute</li>
            //                 <li>6th Offense: Perm mute</li>
            //             </ul>
            //         </Text>
            //     ),
            // },
            {
                title: "INAPPROPRIATE DISPLAYS",
                textVersion:
                    "This applies to usernames, display nicknames, skins, capes, banners, builds, and similar items. The term ‘Inappropriate’ can define a range of things that are considered discriminatory or disrespectful; This includes symbols and slurs. If the player refuses to change their display and staff cannot do it for them, the player will be banned indefinitely until they comply with the rules.",
                content: (
                    <Text variant="p">
                        This applies to usernames, display nicknames, skins,
                        capes, banners, builds, and similar items. The term
                        ‘Inappropriate’ can define a range of things that are
                        considered discriminatory or disrespectful; This
                        includes symbols and slurs. If the player refuses to
                        change their display and staff cannot do it for them,
                        the player will be banned indefinitely until they comply
                        with the rules.
                        <br />
                        <br />
                        <strong className="italic">
                            Inappropriate Details about Character:
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>
                                1st Offense: Warned verbally to change it, given
                                10 minutes to change.
                            </li>
                            <li>2nd Offense Banned until changed</li>
                        </ul>
                        <br />
                        <br />
                        <strong className="italic">
                            Inappropriate Builds | Low Severity (such as builds
                            displaying genitalia):
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>
                                1st Offense: Verbal Warning and Build Removed
                            </li>
                            <li>2nd Offense: 3h Ban and Build Removed</li>
                            <li>3rd Offense: 6h Ban and Build Removed</li>
                        </ul>
                        <br />
                        <br />
                        <strong className="italic">
                            Inappropriate Builds | High Severity (such as
                            swastikas and other items that may be offensive to
                            others):
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>
                                1st Offense: Verbal Warning and Build Removed
                            </li>
                            <li>2nd Offense: 3d Ban and Build Removed</li>
                            <li>3rd Offense: 7d Ban and Build Removed</li>
                            <li>4th Offense: 1 Month Ban and Build Removed</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "SEXUAL HARASSMENT / CONTENT",
                textVersion:
                    "Sexually harassing someone is completely unacceptable and is strictly forbidden. Using sexual remarks in a sentence which is directed at a person is not allowed on our network.",
                content: (
                    <Text variant="p">
                        Sexually harassing someone is completely unacceptable
                        and is strictly forbidden. Using sexual remarks in a
                        sentence which is directed at a person is not allowed on
                        our network.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 1h Mute</li>
                            <li>2nd Offense: 3h Mute</li>
                            <li>3rd Offense: 5h Mute</li>
                            <li>4th Offense: 8h Mute</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "IMPERSONATION",
                textVersion:
                    "Pretending to take another player or staff’s identity with ill intention. Users with similar IGNs will not be punished unless intentionally used to mislead members of the community.",
                content: (
                    <Text variant="p">
                        Pretending to take another player or staff’s identity
                        with ill intention. Users with similar IGNs will not be
                        punished unless intentionally used to mislead members of
                        the community.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: 3h Mute</li>
                            <li>3rd Offense: 6h Mute</li>
                            <li>4th Offense: 12h Mute</li>
                            <li>5th Offense: 1d Mute</li>
                        </ul>
                    </Text>
                ),
            },
            // {
            //     title: "MUTE EVASION",
            //     textVersion:
            //         "Using methods to communicate with others in-game while muted such as with item names, helpop, signs, island names, etc... Anyone who is found helping a muted player convey their messages will receive the same punishment that the muted player did. Getting on an alt account to talk also falls under this rule.",
            //     content: (
            //         <Text variant="p">
            //             Using methods to communicate with others in-game while
            //             muted such as with item names, helpop, signs, island
            //             names, etc... Anyone who is found helping a muted player
            //             convey their messages will receive the same punishment
            //             that the muted player did. Getting on an alt account to
            //             talk also falls under this rule.
            //             <br />
            //             <br />
            //             <strong className="italic">Punishments:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>
            //                     1st Offense: Player’s mute is doubled in length.{" "}
            //                 </li>
            //                 <li>
            //                     2nd Offense: Player banned for the same duration
            //                     as their mute.
            //                 </li>
            //                 <li>3rd Offense: Perm Ban</li>
            //             </ul>
            //         </Text>
            //     ),
            // },
            {
                title: "ADVERTISEMENT",
                textVersion:
                    "The material advertising of quality, goods, and/or services that are not directly related to (or are direct competitors of) SpaceDelta. Advertisements like this, solicited or not, have the potential of drawing players away from the network. NOTE: If a player is clearly only here to advertise - i.e. joining the server and immediately spamming their own server IP, it will be considered Tier 3.",
                content: (
                    <Text variant="p">
                        The material advertising of quality, goods, and/or
                        services that are not directly related to (or are direct
                        competitors of) SpaceDelta. Advertisements like this,
                        solicited or not, have the potential of drawing players
                        away from the network. NOTE: If a player is clearly only
                        here to advertise - i.e. joining the server and
                        immediately spamming their own server IP, it will be
                        considered Tier 3.
                        <br />
                        <br />
                        Players are allowed to say that they are streaming the
                        SpaceDelta server/content. This includes a player
                        sending their stream link, only whilst they are
                        streaming SpaceDelta content .
                        <br />
                        <br />
                        <strong className="italic">
                            Advertising things such as the player’s youtube
                            channel or similar items:
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: 1d Mute</li>
                            <li>3rd Offense: 3d Mute</li>
                            <li>4th Offense: 7d Mute</li>
                            <li>5th Offense: 7d Ban</li>
                        </ul>
                        <br />
                        <br />
                        <strong className="italic">
                            Getting on the server for the sole reason of
                            advertising a server or of the sort:
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 30d Ban</li>
                            <li>2nd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "SENDING LINKS IN CHAT",
                textVersion:
                    "Sending a link which is not directly related to SpaceDelta is not allowed. Such links include but are not limited to a youtube video link, a twitch stream, an amazon item, and more. Sending links to a website of a minecraft server or similar item will fall under the Advertisement rule.",
                content: (
                    <Text variant="p">
                        Sending a link which is not directly related to
                        SpaceDelta is not allowed. Such links include but are
                        not limited to a youtube video link, a twitch stream, an
                        amazon item, and more. Sending links to a website of a
                        minecraft server or similar item will fall under the
                        Advertisement rule.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Verbal Warning</li>
                            <li>2nd Offense: 6h Mute</li>
                            <li>3rd Offense: 12h Mute</li>
                            <li>4th Offense: 1d Mute</li>
                            <li>5th Offense: 1d Ban</li>
                        </ul>
                    </Text>
                ),
            },
        ],
    },
    {
        title: "// TIER 3",
        content: "",
        // "Tier 3 offences are generally extremely serious and damaging to the network or community. These can be met with a mute, temp ban, or an immediate permanent ban.",
        blocks: [
            {
                title: "WASTING STAFF TIME",
                textVersion:
                    "This is defined as contacting a member of staff for the sole purpose of “trolling” or getting their attention. Examples include pretending to hack, spamming them on any platform, etc... This includes the harassment of staff members in-game or over other platforms whether it be in general or in regards to a punishment.",
                content: (
                    <Text variant="p">
                        This is defined as contacting a member of staff for the
                        sole purpose of “trolling” or getting their attention.
                        Examples include pretending to hack, spamming them on
                        any platform, etc... This includes the harassment of
                        staff members in-game or over other platforms whether it
                        be in general or in regards to a punishment.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 3h Mute</li>
                            <li>2nd Offense: 6h Mute</li>
                            <li>3rd Offense: 12h Mute</li>
                            <li>4th Offense: 1d Mute</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "STAFF DISRESPECT",
                textVersion:
                    "This is considered as any act or type of behaviour which is inappropriate, unethical, or outright offensive. It includes many things such as message abuse (via in-game chat, discord, or anywhere else), verbal abuse, and bullying of any sort. This type of behaviour is hurtful and can cause stress to any employee within our Staff Team.",
                content: (
                    <Text variant="p">
                        This is considered as any act or type of behaviour which
                        is inappropriate, unethical, or outright offensive. It
                        includes many things such as message abuse (via in-game
                        chat, discord, or anywhere else), verbal abuse, and
                        bullying of any sort. This type of behaviour is hurtful
                        and can cause stress to any employee within our Staff
                        Team.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 3h Mute</li>
                            <li>2nd Offense: 6h Mute</li>
                            <li>3rd Offense: 12h Mute</li>
                            <li>4th Offense: 1d Mute</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "BUG EXPLOITATION",
                textVersion:
                    "Abusing a bug or glitch - before or after telling a staff member - is considered cheating and bannable. If you’re not sure if something is a bug or intended feature, always ask. If the effects of the exploit are irreversible, it should be met with a permanent ban. ",
                content: (
                    <Text variant="p">
                        Abusing a bug or glitch - before or after telling a
                        staff member - is considered cheating and bannable. If
                        you’re not sure if something is a bug or intended
                        feature, always ask. If the effects of the exploit are
                        irreversible, it should be met with a permanent ban.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 7d Ban</li>
                            <li>2nd Offense: 30d Ban</li>
                            <li>3rd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "CHEATING/HACKING",
                textVersion:
                    "The act of using a third-party client or  Blacklisted Modification(s) in order to give yourself an unfair advantage. Ask a staff member if specific clients are allowed or disallowed.",
                content: (
                    <Text variant="p">
                        Cheating or hacking using a 3rd party client or through
                        any other method is a bannable offense. The severity of
                        the punishment may change based on the severity of the
                        offense.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 30d Ban</li>
                            <li>2nd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "PHISHING",
                textVersion:
                    "This is the practice of sending messages, emails, or other communications while pretending to be someone reputable in order to persuade someone to leak important information. In our case, pretending to be a staff member in order to gain unfair advantages in-game.",
                content: (
                    <Text variant="p">
                        This is the practice of sending messages, emails, or
                        other communications while pretending to be someone
                        reputable in order to persuade someone to leak important
                        information. In our case, pretending to be a staff
                        member in order to gain unfair advantages in-game.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 30d Ban</li>
                            <li>2nd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "DOXING/THREATS OF DOXING",
                textVersion:
                    "The search for and publication of private information about an individual on the internet, especially with malicious intent.",
                content: (
                    <Text variant="p">
                        The search for and publication of private information
                        about an individual on the internet, especially with
                        malicious intent..
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Perm Blacklist</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "MALICIOUS LINKS",
                textVersion:
                    "Sending any type of malicious link (such as IP grabbers) in chat, private messages or such is strictly forbidden.",
                content: (
                    <Text variant="p">
                        The search for and publication of private information
                        about an individual on the internet, especially with
                        malicious intent..
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Perm Blacklist</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "SELLING/PURCHASING ILLEGAL ITEMS",
                textVersion:
                    "Using any part of our network as a platform for the trading, selling, and / or purchasing items that are either illegal themselves or were acquired illegally is strictly prohibited.",
                content: (
                    <Text variant="p">
                        The sale of illegal items that were generated through
                        glitching, duping, or other methods.
                        <br />
                        <br />
                        <strong className="italic">Punishments</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>
                                1st Offense: Item removed and 3h Ban to all
                                parties involved.
                            </li>
                            <li>
                                2nd Offense: Item removed and 6h Ban to all
                                parties involved.
                            </li>
                            <li>
                                3rd Offense: Item removed and 12h Ban to all
                                parties involved.
                            </li>
                        </ul>
                        <br />
                        <br />
                        <strong className="italic">
                            High Severity (Duped items, glitched out items,
                            etc..)
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>
                                1st Offense: Item removed and 3d Ban to all
                                parties involved.
                            </li>
                            <li>
                                2nd Offense: Item removed and 7d Ban to all
                                parties involved.
                            </li>
                            <li>
                                3rd Offense: Item removed and 14d Ban to all
                                parties involved.
                            </li>
                            <li>
                                4th Offense: Item removed and 30d Ban to all
                                parties involved.
                            </li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "SUICIDAL ENCOURAGEMENT",
                textVersion:
                    "Telling someone else to kill or harm themselves in any way is extremely damaging and illegal under Federal Law. We have an absolute zero-tolerance policy for players who do so regardless of whether or not they are kidding around with friends. This is extremely insensitive and tasteless humor and will not be taken lightly.",
                content: (
                    <Text variant="p">
                        Telling someone else to kill or harm themselves in any
                        way is extremely damaging and illegal under Federal Law.
                        We have an absolute zero-tolerance policy for players
                        who do so regardless of whether or not they are kidding
                        around with friends. This is extremely insensitive and
                        tasteless humor and will not be taken lightly.
                        <br />
                        <br />
                        <strong className="italic">Punishments</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 7d Mute</li>
                            <li>2nd Offense: 7d Ban</li>
                            <li>3rd Offense: 14d Ban</li>
                            <li>4th Offense: 30d Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "THREATS OF HARM / DEATH",
                textVersion:
                    "Similarly to suicidal encouragement, threatening to or hinting at hurting or killing a player / a player’s family is illegal. Light joking about this among friends may be tolerated in consideration with the severity of what was said.",
                content: (
                    <Text variant="p">
                        Similarly to suicidal encouragement, threatening to or
                        hinting at hurting or killing a player / a player’s
                        family is illegal. Light joking about this among friends
                        may be tolerated in consideration with the severity of
                        what was said.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 7d Mute</li>
                            <li>2nd Offense: 14d Mute</li>
                            <li>3rd Offense: 30d Mute</li>
                            <li>4th Offense: 14d Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "BOTTING",
                textVersion:
                    "Logging in with multiple accounts / bots to perform the same action will result in a permanent ban due to the sheer amount of damage that multi-account botting can cause to a server. Console clients who are meant to afk chunks are allowed. ",
                content: (
                    <Text variant="p">
                        Logging in with multiple accounts / bots to perform the
                        same action will result in a permanent ban due to the
                        sheer amount of damage that multi-account botting can
                        cause to a server. Console clients who are meant to afk
                        chunks are allowed.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "FORGING EVIDENCE",
                textVersion:
                    "Attempting to provide evidence which has been edited or changed in any way with malicious intent to get another player punished or providing false information in a report / appeal.",
                content: (
                    <Text variant="p">
                        Attempting to provide evidence which has been edited or
                        changed in any way with malicious intent to get another
                        player punished or providing false information in a
                        report / appeal.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 30d Ban</li>
                            <li>2nd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            // {
            //     title: "WITHHOLDING EVIDENCE",
            //     textVersion:
            //         "The act of having evidence of an individual and / or a party breaking a rule but not reporting it is not allowed on our network. If you have evidence of someone breaking our rules but you choose not to report it, you will be punished. If you have such evidence, report it ASAP. Withholding evidence is only punishable if the evidence has been withheld for over 7 days.",
            //     content: (
            //         <Text variant="p">
            //             Attempting to provide evidence which has been edited or
            //             changed in any way with malicious intent to get another
            //             player punished or providing false information in a
            //             report / appeal.
            //             <br />
            //             <br />
            //             <strong className="italic">Punishments:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>1st Offense: 7d Ban</li>
            //                 <li>2nd Offense: 14d Ban</li>
            //                 <li>3rd Offense: 30d Ban</li>
            //             </ul>
            //         </Text>
            //     ),
            // },
            {
                title: "BAN EVASION",
                textVersion:
                    "Logging into the network on an alt account to avoid your ban.",
                content: (
                    <Text variant="p">
                        Logging into the network on an alt account to avoid your
                        ban.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Same length IP Ban</li>
                        </ul>
                    </Text>
                ),
            },
            // {
            //     title: "BLACKMAILING",
            //     textVersion:
            //         "The act of using something against someone to make them do as you say is strictly forbidden on our network. The punishments under this rule are subject to change depending on the severity of the offense.",
            //     content: (
            //         <Text variant="p">
            //             The act of using something against someone to make them
            //             do as you say is strictly forbidden on our network. The
            //             punishments under this rule are subject to change
            //             depending on the severity of the offense.
            //             <br />
            //             <br />
            //             <strong className="italic">Punishments:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>1st Offense: 14d Ban</li>
            //                 <li>2nd Offense: 30d Ban</li>
            //                 <li>3rd Offense: Perm Ban</li>
            //             </ul>
            //         </Text>
            //     ),
            // },
            {
                title:
                    "AIDING / TEAMING WITH A HACKER / BANNED PLAYER FROM THE NETWORK",
                textVersion:
                    "Aiding a player who is using a blacklisted modification or who is breaking the rules is not allowed on our network. Giving the hacker items to help you in PvP also falls under this rule. ",
                content: (
                    <Text variant="p">
                        Aiding a player who is using a blacklisted modification
                        or who is breaking the rules is not allowed on our
                        network. Giving the hacker items to help you in PvP also
                        falls under this rule.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 14d Ban</li>
                            <li>2nd Offense: 30d Ban</li>
                            <li>3rd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
        ],
    },
    {
        title: "// SKYBLOCK RULES",
        blocks: [
            {
                title: "INSIDING / GRIEFING AN ISLAND",
                textVersion:
                    "Joining a player’s island with the sole intent of damaging or stealing items is not allowed. If your island is griefed and items are stolen, make a ticket to get it resolved.",
                content: (
                    <Text variant="p">
                        Joining a player’s island with the sole intent of
                        damaging or stealing items is not allowed. If your
                        island is griefed and items are stolen, make a ticket to
                        get it resolved.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: 14d Ban</li>
                            <li>2nd Offense: 30d Ban</li>
                            <li>3rd Offense: Perm Ban</li>
                        </ul>
                    </Text>
                ),
            },
            // {
            //     title: "AFK MINING / AUTO-CLICKING",
            //     textVersion:
            //         "AFK Mining is not allowed. Any blacklisted modifications such as AutoClickers, MobAura, or any other alternate blacklisted modification is not allowed on our network. This does not include the F11 glitch. Using any type of script or macro would also fall under this rule.",
            //     content: (
            //         <Text variant="p">
            //             AFK Mining is not allowed. Any blacklisted modifications
            //             such as AutoClickers, MobAura, or any other alternate
            //             blacklisted modification is not allowed on our network.
            //             This does not include the F11 glitch. Using any type of
            //             script or macro would also fall under this rule.
            //             <br />
            //             <br />
            //             AFK mining includes auto fishing farms. The same rules
            //             will apply if a player has been caught with an auto
            //             fishing contraption on your island, even if they have
            //             not used it.
            //             <br />
            //             <br />
            //             <strong className="italic">Punishments:</strong>
            //             <br />
            //             <br />
            //             <ul className="list-disc list-inside ">
            //                 <li>1st Offense: Kick</li>
            //                 <li>2nd Offense: 7d Ban</li>
            //                 <li>3rd Offense: 14d Ban</li>
            //                 <li>4th Offense: 30d Ban</li>
            //                 <li>4th Offense: Perm Ban</li>
            //             </ul>
            //         </Text>
            //     ),
            // },
            {
                title: "SCAMMING",
                textVersion:
                    "Scamming and lying are allowed except for the following exceptions:",
                content: (
                    <Text variant="p">
                        Scamming and lying are allowed except for the following
                        exceptions:
                        <br />
                        <br />
                        <ul className="list-disc list-inside">
                            <li>
                                Staff members are NOT allowed to scam or lie to
                                players.
                            </li>
                            <li>
                                IRL / PayPal money must not be directly involved
                                in the scam.
                            </li>
                            <li>
                                Listing fake, glitched-in, or inaccurately
                                renamed items for sale in the Auction House or
                                ‘AH’.
                            </li>
                        </ul>
                        <br/>
                        <strong className="italic">
                            Exceptions fall under Tier 3 punishments:
                        </strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Moderator's Discretion</li>
                        </ul>
                    </Text>
                ),
            },
            {
                title: "BREAKING SPAWNERS",
                textVersion:
                    "If you break your spawner without silk touch and a rank, your spawners will NOT be replaced. If another player on your island breaks your spawner without silk touch and a rank with the intent to cause damage to the island, this will fall under Insiding . Griefing an Island. If this player does this accidentally, no punishment will be given.",
                content: (
                    <Text variant="p">
                        If you break your spawner without silk touch and a rank,
                        your spawners will NOT be replaced. If another player on
                        your island breaks your spawner without silk touch and a
                        rank with the intent to cause damage to the island, this
                        will fall under Insiding / Greifing an Island. Griefing
                        an Island. If this player does this accidentally, no
                        punishment will be given.
                        <br />
                        <br />
                        <strong className="italic">Punishments:</strong>
                        <br />
                        <br />
                        <ul className="list-disc list-inside ">
                            <li>1st Offense: Moderator's Discretion</li>
                        </ul>
                    </Text>
                ),
            },
        ],
    },
];

function Rules(): ReactElement {
    const data = useStaticQuery(graphql`
        {
            background: file(
                relativePath: { eq: "background_header_blurred.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            post: ghostPost(slug: { eq: "rules" }) {
                title
                slug
                feature_image
                authors {
                    name
                }
                created_at
                updated_at
                excerpt

                html
            }
            page_bg: file(relativePath: { eq: "page_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const pageBg = [
        `linear-gradient(hsla(0, 0%, 6%, .95), hsla(0, 0%, 6%, .95))`,

        data.page_bg.childImageSharp.fluid,
    ];

    return (
        <Layout>
            <SEO
                title={data.post.title}
                article={true}
                articleAuthor={data.post.authors}
                description={data.post.excerpt}
                image={data.post.feature_image}
                imageAlt={data.post.excerpt}
                articleDatetime={data.post.created_at}
                articleModifiedTime={data.post.updated_at}
            />
            <article>
                {/* Header */}
                <BackgroundImage
                    fluid={data.background.childImageSharp.fluid}
                    className="z-10"
                >
                    <section className="container relative p-4 mx-auto h-newsHeaderSmall lg:h-postHeader md:p-8">
                        <motion.div
                            initial={{ y: 500, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                damping: 20,
                                type: "spring",
                                mass: 1.5,
                            }}
                            className="flex items-center w-full h-full md:px-4 lg:px-12"
                        >
                            <img
                                src={data.post.feature_image}
                                alt={data.post.title}
                                className="object-cover w-full max-h-full md:transform md:translate-y-16"
                            />
                        </motion.div>

                        {/* Overlay */}
                        <div className="absolute inset-0 w-full px-2 transform translate-y-4 lg:w-9/12 lg:translate-x-40 lg:translate-y-16 lg:h-postDivOverlayLarge md:px-6 h-postDivOverlay">
                            <motion.div
                                initial={{ scale: 5 }}
                                animate={{ scale: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 1,
                                    delay: 0.5,
                                    bounce: 0,
                                }}
                                className="flex items-end w-full h-full border-2 border-gray-500 "
                            >
                                <div className="w-full">
                                    <div className="p-4 lg:p-8">
                                        <h1 className="mb-2 text-2xl font-semibold">
                                            {data.post.title}
                                        </h1>
                                        <p className="my-0">
                                            {data.post.excerpt}
                                        </p>
                                    </div>
                                    <div className="flex w-full">
                                        <div className="flex items-center flex-1 p-3 border-t-2 border-r-2 border-gray-500 lg:p-8">
                                            <h3 className="mb-0 text-xs font-semibold text-gray-500 uppercase">
                                                <span className="mr-2">
                                                    Date:
                                                </span>{" "}
                                                <span>
                                                    {" "}
                                                    {moment(
                                                        data.post.created_at
                                                    ).format("MMMM Do, YYYY")}
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="flex items-center flex-1 p-3 border-t-2 border-gray-500 lg:p-8">
                                            <h3 className="mb-0 text-xs font-semibold text-gray-500 uppercase">
                                                <span className="mr-2">
                                                    {data.post.authors.length >
                                                    1
                                                        ? "Authors:"
                                                        : "Author:"}
                                                </span>{" "}
                                                <span>
                                                    {data.post.authors.map(
                                                        ({
                                                            name,
                                                        }: {
                                                            name: string;
                                                        }) => (
                                                            <span key={name}>
                                                                {name}
                                                            </span>
                                                        )
                                                    )}
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </section>
                </BackgroundImage>
                <BackgroundImage fluid={pageBg} className="bg-repeat">
                    <Section
                        style={{ paddingTop: "30vh", maxWidth: 1024 }}
                        className="space-y-12"
                    >
                        <div className="space-y-8">
                            <Text variant="h2">Overview</Text>
                            <Text variant="p">
                                By joining our server, you automatically accept
                                all of our rules listed below and are
                                responsible for any violation(s).
                            </Text>
                            <Text variant="p">
                                Any suggestions regarding rule creation and
                                edits should be made via tickets in our Discord.
                                If you feel a rule should be changed or added,
                                please make a ticket in the Discord and a member
                                of the administration team will discuss it with
                                you. A notice will be given on our Discord
                                server regarding any rule changes.
                            </Text>
                        </div>

                        <div className="space-y-12">
                            {rules.map((section) => (
                                <div className="space-y-6">
                                    <Text
                                        variant="h3"
                                        className={`tracking-wide ${
                                            section.small
                                                ? "text-xl"
                                                : "text-2xl lg:text-3xl"
                                        }`}
                                    >
                                        {section.title}
                                    </Text>

                                    {section.content && (
                                        <Text variant="p" className="">
                                            {section.content}
                                        </Text>
                                    )}

                                    {section.blocks && (
                                        <div className="space-y-4">
                                            {section.blocks.map(
                                                ({
                                                    title,
                                                    content,
                                                    textVersion,
                                                }) => (
                                                    <Rule
                                                        title={title}
                                                        content={content}
                                                        textVersion={
                                                            textVersion
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </Section>
                </BackgroundImage>
            </article>
        </Layout>
    );
}

export default Rules;
