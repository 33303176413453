import React, { useState, useRef, useEffect, useCallback } from "react"

import { motion } from "framer-motion"

import Text from "./Text"
import { AnimatePresence } from "framer-motion"

interface Props {
  title: string
  content: string | React.ReactNode
  textVersion?: string
  className?: string
}

const Rule = ({ title, content, className, textVersion }: Props) => {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState(0)

  // Refs
  const contentContainer = useRef(null)
  const textHeight = useRef(null)

  // Helpers
  useEffect(() => {
    if (open) {
      // Removing the padding from the height
      setHeight(textHeight.current.offsetHeight - 40)
    } else {
      setHeight(0)
    }
  }, [open])

  return (
    <div
      className={`cursor-pointer ${className || ""}`}
      onClick={() => setOpen(!open)}
    >
      <div
        className={`sm:p-10 p-6 flex justify-between items-center transition-all duration-300 ease-easeInOutCubic bg-gray-700 ${
          open ? "rounded-t-md" : "rounded-md"
        }`}
      >
        <div className="relative flex overflow-x-hidden">
          <Text variant="h3" className="md:whitespace-nowrap">
            {title}
          </Text>

          <AnimatePresence className="hidden md:block">
            {!open && (
              <motion.div
                transition={{
                  type: "tween",
                  ease: [0.65, 0, 0.35, 1],
                  duration: 0.3,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Text
                  variant="p"
                  className="hidden ml-8 opacity-50 whitespace-nowrap md:block"
                >
                  {textVersion || content}
                </Text>

                <Text
                  variant="p"
                  className="absolute top-0 right-0 hidden pl-1 bg-gray-700 md:block"
                >
                  <span className="opacity-50">...</span>
                </Text>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <motion.div
        transition={{
          type: "tween",
          ease: [0.65, 0, 0.35, 1],
          duration: 0.3,
        }}
        initial="hide"
        animate={open ? "show" : "hide"}
        variants={{ hide: { height }, show: { height } }}
        ref={contentContainer}
        className={`overflow-hidden`}
      >
        <div
          ref={textHeight}
          className="flex p-6 space-x-4 space-y-6 transition-all duration-500 transform -translate-y-6 bg-gray-700 sm:p-10 rounded-b-md ease-easeInOutCubic sm:-translate-y-10"
        >
          <Text className="text-gray-300 opacity-70" variant="p">
            {content}
          </Text>
        </div>
      </motion.div>
    </div>
  )
}

export default Rule
